import React from "react"
import { servicesDetails } from "../data"
import { v4 as uuidv4 } from "uuid"
import { StaticImage } from "gatsby-plugin-image"

export default function Services() {
  return (
    <section className="Services py-5 mb-6" id="services">
      <h2 className="title">Nos services</h2>
      <div className="columns">
        {servicesDetails.map(service => {
          return (
            <div className="column" key={uuidv4()}>
              <div className="card">
                <div className="card-image">
                  {service.image === "services_swimming-pool.jpg" && (
                    <StaticImage
                      quality={10}
                      placeholder="blurred"
                      src={`../images/DSCN6080.jpg`}
                      alt={service.placeholder}
                    />
                  )}
                  {service.image === "services_green-space.jpg" && (
                    <StaticImage
                      quality={10}
                      placeholder="blurred"
                      src={`../images/green-test.jpg`}
                      alt={service.placeholder}
                    />
                  )}
                  {service.image === "services_tools.jpg" && (
                    <StaticImage
                      quality={10}
                      placeholder="blurred"
                      src={`../images/tools-test.jpg`}
                      alt={service.placeholder}
                    />
                  )}
                </div>
                <div className="card-content" style={{ textAlign: "center" }}>
                  <div className="media-content">
                    <p
                      className="title is-4"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {service.name}
                    </p>
                  </div>
                  <div className="content">
                    <table className="table is-stripped">
                      <tbody key={uuidv4()}>
                        {service.data.map(item => {
                          // TODO: changer cet affreux html
                          return (
                            <tr>
                              <th style={{ border: "none" }}>{item}</th>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
