import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

export default function Header() {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "header.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 10
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage)
  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2))`,
    pluginImage,
  ]
  return (
    <BgImage
      className="hero is-fullheight-with-navbar Header"
      id="header"
      image={backgroundFluidImageStack}
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <header className="hero-body">
        <div
          className="container py-4 is-fullhd has-text-centered"
          style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
        >
          <h1
            className="title is-1 has-text-white"
            style={{ fontSize: "5rem" }}
          >
            L'Ô{" "}
            <span
              className="title is-3 has-text-white"
              style={{ fontSize: "3.5rem" }}
            >
              DU JARDIN
            </span>
          </h1>
          <p className="subtitle has-text-white" style={{ fontSize: "2rem" }}>
            Espaces verts, petits travaux, <br /> entretien piscine et résidence
            secondaire
          </p>
        </div>
      </header>
    </BgImage>
  )
}
