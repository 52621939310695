import React from "react"
// import contactImage from "../images/contact.svg"
// import MapContainer from "./MapContainer"
import LeaftletMap from "./LeafletMap"

export default function Contact() {
  return (
    <section
      className="Contact py-5 mb-6"
      id="contact"
      // style={{ minHeight: "100vh" }}
    >
      <h2 className="title">Contactez nous</h2>
      <div className="columns">
        <div
          className="column"
          // style={{
          //   backgroundImage: `url(${contactImage})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   // check https://www.digitalocean.com/community/tutorials/how-to-change-a-css-background-images-opacity
          // }}
        >
          <div className="content">
            <p>
              Vous pouvez nous contacter en cliquant (depuis votre smartphone)
              sur le numéro de téléphone ci-dessus ou par e-mail{" "}
              <a
                href="mailto:contact@lodujardin.fr"
                target="_blank"
                rel="noreferrer"
              >
                en cliquant ici
              </a>
            </p>
            <p>
              Nous intervenons dans un rayon d'environ 15km autour d'Uzès (ex :
              Connaux, Vallérargues, Sainte Anastasie, Poulx, Rémoulins, etc.)
            </p>

            <p>À très bientôt</p>
          </div>
        </div>
        <div className="column">
          <LeaftletMap />
        </div>
      </div>
    </section>
  )
}
