import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "!mapbox-gl"
// import "mapbox-gl/dist/mapbox-gl.css"npm i mapbox-gl/dist/mapbox-gl.

const mapContainerStyle = {
  height: "500px",
  width: "100%",
}

const defaultCenter = {
  lat: 44.012516,
  lng: 4.4201373,
}

const LeafletMap = () => {
  const mapContainerRef = useRef(null)

  const [map, setMap] = useState(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: process.env.MAPBOX_API_KEY,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [defaultCenter.lng, defaultCenter.lat],
      zoom: 11,
    })
    map.addControl(new mapboxgl.NavigationControl(), "top-right")

    setMap(map)

    return () => map.remove()
  }, [])

  return <div ref={mapContainerRef} style={mapContainerStyle} />
}

export default LeafletMap
