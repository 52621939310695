export const servicesDetails = [
  {
    name: "entretien piscine",
    image: "services_swimming-pool.jpg",
    placeholder: "swimming pool",
    data: [
      "ouverture début de saison",
      "visite hebdomadaire période estivale",
      "fermeture fin de saison (hivernage)",
      "dépannage/installation filtration (devis)",
    ],
  },
  {
    name: "espace vert",
    image: "services_green-space.jpg",
    placeholder: "green space",
    data: [
      "tonte",
      "taille de haie, oliviers, muriers",
      "coupe arbuste, arbres (max 5m)",
      "jardinage",
    ],
  },
  {
    name: "petits travaux",
    image: "services_tools.jpg",
    placeholder: "tools",
    data: [
      "travaux de peinture",
      "pose terrasse en bois, bardage bois",
      "carrelage petite surface",
      "fixation étagères, cadres, kits rideaux",
      "remplacement robinet, prise électrique",
      "entretien volets, portes, portails, terrasse, clôtures",
      "petits travaux de maçonneries",
    ],
  },
]
