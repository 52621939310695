import React from "react"
import Layout from "../components/Layout"
import "bulma/css/bulma.min.css"
import "./index.css"
import "@fontsource/montserrat"
import Header from "../components/Header"
import Services from "../components/Services"
import Contact from "../components/Contact"
import Seo from "../components/Seo"

export default function Home() {
  return (
    <Layout>
      <Seo />
      <Header />
      <div className="container is-fluid">
        <Services />
        <Contact />
      </div>
    </Layout>
  )
}
